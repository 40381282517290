@font-face {
  font-family: 'wita';
  src: url('../other/wita.woff2?2655323') format('woff2'),
       url('../other/wita.woff?2655323') format('woff');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'wita';
    src: url('../font/wita.svg?2655323#wita') format('svg');
  }
}
*/
[class^="iwita-"]:before, [class*=" iwita-"]:before {
  font-family: "wita";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  text-align: center;

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

// Convenience function used to set content property
@function iwita-content($iwita-var) {
    @return unquote("\"#{ $iwita-var }\"");
}

$iwita-angle-down: \e800;
$iwita-angle-up: \e801;
$iwita-back: \e802;
$iwita-button-slideshow-advance: \e803;
$iwita-button-slideshow-back: \e804;
$iwita-button-slideshow-play: \e806;
$iwita-calendar: \e807;
$iwita-check: \e808;
$iwita-close: \e809;
$iwita-extern-link: \e80a;
$iwita-info: \e80b;
$iwita-menu: \e80c;
$iwita-search: \e80d;
$iwita-law: \e80f;
$iwita-button-slideshow-pause: \e810;

.iwita-angle-down:before { content: iwita-content($iwita-angle-down); } /* '' */
.iwita-angle-up:before { content: iwita-content($iwita-angle-up); } /* '' */
.iwita-back:before { content: iwita-content($iwita-back); } /* '' */
.iwita-button-slideshow-advance:before { content: iwita-content($iwita-button-slideshow-advance); } /* '' */
.iwita-button-slideshow-back:before { content: iwita-content($iwita-button-slideshow-back); } /* '' */
.iwita-button-slideshow-play:before { content: iwita-content($iwita-button-slideshow-play); } /* '' */
.iwita-calendar:before { content: iwita-content($iwita-calendar); } /* '' */
.iwita-check:before { content: iwita-content($iwita-check); } /* '' */
.iwita-close:before { content: iwita-content($iwita-close); } /* '' */
.iwita-extern-link:before {
  content: iwita-content($iwita-extern-link);
  font-size: 60%;
  vertical-align: middle;
} /* '' */
.iwita-info:before { content: iwita-content($iwita-info); } /* '' */
.iwita-menu:before { content: iwita-content($iwita-menu); } /* '' */
.iwita-search:before { content: iwita-content($iwita-search); } /* '' */
.iwita-law:before { content: iwita-content($iwita-law); } /* '' */
.iwita-button-slideshow-pause:before { content: iwita-content($iwita-button-slideshow-pause); } /* '' */
