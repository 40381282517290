.umbrella-jumbotron {
  padding-bottom: rem(250);
  margin-bottom: rem(-200);
  background-color: $light;

  .jumbotron-img {
    position: relative;

    img {
      position: absolute;
      top: 0;
      right: 0;
      width: 635px;
    }
  }
}

.amateur-jumbotron {
  padding-bottom: rem(120);
  margin-bottom: rem(-60);
  background-color: $light;

  @include media-breakpoint-up(lg) {
    .jumbotron-img {
      position: relative;
      height: 380px;

      img {
        position: absolute;
        right: 15px;
        top: 0;
        bottom: 0;
        height: 100%;
      }
    }
  }

  @include media-breakpoint-down(md) {
    padding-bottom: rem(90);
    margin-bottom: rem(-45);

    .jumbotron-img {
      img {
        width: auto;
        max-width: 100%;
      }
    }
  }
}
