.cta-block {
  background-color: $red;
  color: #fff;

  align-items: center;

  border-radius: rem(15);

  overflow: hidden;

  .cta-block-text {
    padding: rem(40);
  }

  .cta-block-action {
    padding: rem(40) rem(60);
    background: url(../img/motif_banner.svg) no-repeat left center / cover;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      white-space: nowrap;
    }
  }
}
