.swiper {
  overflow: hidden;
  padding: 10px 0;

  .swiper-navigation {
    position: relative;
    z-index: 11;
    margin-top: rem(40);

    .swiper-prev, .swiper-next {
      width: rem(32);
      height: rem(32);
      background-color: $dark;
      border-radius: rem(16);
      position: relative;

      & > i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 75%;
      }

      &.swiper-button-disabled {
        background-color: white;
        & > i {
          color: $gray-500;
        }
      }
    }

    .swiper-prev {
      margin-right: rem(10);
    }
  }

  .swiper-pagination {
    width: 220px !important;
    margin-left: auto;
    right: 0;
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    opacity: 1;
    background-color: transparent;
    border: 1px solid $dark;
  }

  .swiper-pagination-bullet-active {
    background-color: $dark;
    border-width: 8px;
  }
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 22px;
}

@include media-breakpoint-up(md) {
  .swiper .swiper-pagination {
    margin-right: auto;
    justify-content: center;
  }
}
