.badge-round {
  position: relative;
  display: block;
  width: rem(64);
  height: rem(64);
  border-radius: rem(32);

  & > i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: rem(30);
  }
}
