.outbound-left {
  position: relative;
  border-top-right-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;

  &::before {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    bottom: 0;
    left: -1000%;
    right: -30px;
    z-index: 0;
    border-top-right-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
  }

  & > * {
    z-index: 1;
    position: relative;
  }
}
