.pagination {
  .page-item:first-child .page-link {
    border-top-left-radius: rem(4);
    border-bottom-left-radius: rem(4);
  }
  .page-item:last-child .page-link {
    border-top-right-radius: rem(4);
    border-bottom-right-radius: rem(4);
  }
}
