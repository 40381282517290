.layout-language-selection {
  background-color: $gray-300;
  display: flex;
  align-items: center;
  min-height: 100vh;
  background-image: url(../img/motif_h1.svg);
  background-repeat: no-repeat;
  background-position: -70px -90px;
  background-size: 300px 300px;

  @include media-breakpoint-up(lg) {
    background-position: -350px -90px;
    background-size: auto;
  }

  @media screen and (min-width: 1550px) {
    background-position: -70px -90px;
  }
}
