@each $name, $size in $text-sizes {
  .#{$name} { font-size: $size !important; }
}

@each $name, $size in $text-sizes-mobile {
  @include media-breakpoint-down(md) {
    .#{$name} { font-size: $size !important; }
  }
}

.lh-1 { line-height: 1; }
.line-height-big, .lh-base { line-height: 1.5; }
