.header-be {
  background-color: white;
}

.language-switch {
  a.active {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 6px;
      height: 1px;
      background-color: $red;
    }
  }
}


#mobile-menu {
  display: none;

  &.show {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 86px;
    z-index: 99;
    background: white;
    box-shadow: 0px 20px 20px rgba($gray-500, 0.6);
  }
}

.mobile-header {
  position: relative;

  .navbar .navbar-nav {
    flex-grow: 1;

    .nav-link {
      display: inline-block;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .dropdown-menu {
    border: 0;
    padding: 0 1.5rem 0;
    margin: 0;

    .dropdown-item {
      padding: 0.5rem 0;
      display: inline-block;
      width: auto;

      &:hover, &:focus, &.active {
        background-color: transparent;
        color: $dark;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 6px;
          height: 2px;
          background-color: $red;
        }
      }
    }
  }
}
