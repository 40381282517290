.layout-404 {
  margin-top: 136px;
  background-color: $light;
  min-height: calc(100vh - 294px);

  .visuel-404 {
    text-align: center;
    position: relative;
    top: -136px;
  }

  .container {
    margin-top: -100px;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 100px;

    .visuel-404 {
      width: 150px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      top: -70px;
    }

    .container {
      margin-top: -30px;
    }
  }
}
