.more-info {
  .container {
    position: relative;
    padding-top: rem(205);
    margin-top: rem(-147);
    padding-bottom: rem(30);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: rem(-30);
      right: -1000%;
      bottom: 0;
      background-color: $light;
      border-bottom-left-radius: rem(15);
    }
  }

  @include media-breakpoint-down(md) {
    .container {
      padding-top: rem(157);
    }
  }
}
