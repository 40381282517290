.collapse-wrapper {
  padding: rem(30) 0;

  &:not(:last-child) {
    border-bottom: 1px solid $light;
  }

  [data-toggle="collapse"] {
    position: relative;
    display: block;

    padding-left: 2rem;

    font-size: rem(20);
    font-weight: 700;

    text-decoration: none;

    &:before {
      position: absolute;
      top: rem(6);
      left: 0;
      @include font-wita-icon($iwita-angle-down, 12);
      font-weight: 400;
    }

    &[aria-expanded="true"] {
      &:before {
        @include font-wita-icon($iwita-angle-up, 12);
      }
    }
  }

  .collapse-inner {
    padding-top: rem(20);
    padding-left: 2rem;
  }
}
