.ems-form-custom {
  max-width: 560px;

  .form-control {
    max-width: 100%;
  }

  .custom-file-input:hover {
    cursor: pointer;
  }

  .form-group.required {
    label .required-asterisk {
      &::after {
        color: $red;
        content: ' *';
      }
    }
  }

  .badge-danger {
    background-color: $red-wcag;
    padding-top: 0.4em;
  }

  .invalid-feedback {
    color: $red-wcag;
  }

  .form-control.is-invalid, .custom-select.is-invalid {
    border-color: $red-wcag;
  }

  .custom-select.is-invalid {
    background-position-x: calc(100% - 1rem), calc(100% - 2rem);
  }

  .form-error-icon {
    vertical-align: middle;
  }
}
