.page-heading {
  padding: rem(30) 0;
  background-color: $light;
  background-image: url(../img/motif_h1.svg);
  background-repeat: no-repeat;
  background-position: calc(50% - 420px) 50%;

  @include media-breakpoint-down(md) {
    background-position: calc(50% - 250px) 50%;
    background-size: auto 150%;
  }
}
