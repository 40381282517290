footer {
  border-top: 1px solid $light;
  padding-top: rem(40);
  padding-bottom: rem(50);
}

.footer-pushed {
  footer {
    margin-top: rem(100);
  }
}
