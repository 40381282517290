.ff-primary, .font-family-primary {
  font-family: $font-family-primary;
}

.ff-secondary, .font-family-secondary {
  font-family: $font-family-secondary;
}

.ff-base, .font-family-base {
  font-family: $font-family-base;
}

.ff-headings, .font-family-headings {
  font-family: $headings-font-family;
}
