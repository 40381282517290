.wysiwyg-content {
  h1, .h1 {
    font-size: rem(40);
    font-family: $font-family-secondary;
    font-weight: 300;
  }

  h2, .h2 {
    font-size: rem(32);
    font-family: $font-family-secondary;
    font-weight: 300;

    &:not(:first-child) {
      margin-top: rem(50);
    }

    &:not(:last-child) {
      margin-bottom: rem(30);
    }
  }

  h3, .h3 {
    font-size: rem(24);
    font-family: $font-family-primary;
    font-weight: 700;

    &:not(:first-child) {
      margin-top: rem(30);
    }

    &:not(:last-child) {
      margin-bottom: rem(20);
    }
  }

  h4, .h4 {
    font-size: rem(20);
    font-family: $font-family-primary;
    font-weight: 400;

    &:not(:first-child) {
      margin-top: rem(30);
    }

    &:not(:last-child) {
      margin-bottom: rem(15);
    }
  }

  blockquote {
    padding-left: rem(20);
    position: relative;
    margin-top: 2rem;
    margin-bottom: 2rem;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 4px;
      background-color: $red;
    }

    & > *:first-child {
      font-weight: 700;
    }
  }

  @include media-breakpoint-down(md) {
    h1, .h1 {
      font-size: rem(32);
    }

    h2, .h2 {
      font-size: rem(30);
    }

    h3, .h3 {
      font-size: rem(22);
    }

    h4, .h4 {
      font-size: rem(18);
    }
  }

  .faq-container {
    max-width: none;
    padding-left: 0;
    padding-right: 0;

    .row {
      margin-left: 0;
      margin-right: 0;

      .col-md-10.offset-md-1.col-12 {
        margin-left: 0;
        padding: 0;
        flex: 1;
        max-width: none;
      }
    }
  }
}
