.btn {
  &.btn-round {
    position: relative;
    width: 40px;
    height: 40px;

    & > i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.btn-dark {
    &:hover, &:active {
      background-color: transparent !important;
      color: $dark !important;
    }
  }
}
