@use 'sass:math' as math;

$admin-menu-icon-fill: 'white';
$admin-menu-icon-size: 16;
$admin-menu-font-color: #FFFFFF;
@import "~@elasticms/admin-menu/css/style";

@import "abstract/all";
@import "vendor/all";
@import "utilities/all";
@import "base/all";
@import "components/all";

@import "layout/all";
@import "pages/all";

@import "custom";
@import "debug";