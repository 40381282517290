.search-filters {
  [data-toggle="collapse"] {
    position: relative;
    display: block;

    padding-right: 2rem;

    font-size: rem(24);
    font-weight: 700;

    text-decoration: none;

    &:before {
      position: absolute;
      top: rem(4);
      right: 0;
      @include font-wita-icon($iwita-angle-down, 8);
      font-weight: 400;
    }

    &[aria-expanded="true"] {
      &:before {
        @include font-wita-icon($iwita-angle-up, 8);
      }
    }
  }
}

.search-filters-title {
  margin-top: rem(20);
  @include media-breakpoint-up(lg) {
    border-bottom: 1px solid $dark;
    margin-top: rem(0);
    padding-bottom: rem(20);
    margin-bottom: rem(20);
  }
}

.search-filter-input {
  input {
      border-top-left-radius: $input-border-radius;
      border-bottom-left-radius: $input-border-radius;
      background-color: $white;
      border: 1px solid $dark;
      border-right: 0;
      padding-left: 1rem;
      padding-right: 1rem;
  }

  .input-group-append button, .input-group-text {
      border-top-right-radius: $input-border-radius;
      border-bottom-right-radius: $input-border-radius;
      background-color: $white;
      border: 1px solid $dark;
      border-left: 0;
      padding-right: 1rem;
  }

  .input-group-text {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding: 0 1rem 0 0;
  }
}

.search-group {
  max-width: 560px;

  input {
    border-right: 0;
    padding-left: 1rem;
  }

  .input-group-append {
    .btn {
      border-left: 0;
    }

    .btn:not(:last-child) {
      background-color: white;
      border-top: 1px solid $dark;
      border-bottom: 1px solid $dark;
    }

    .btn:last-child {
      background-color: white;
      border-top: 1px solid $dark;
      border-right: 1px solid $dark;
      border-bottom: 1px solid $dark;

      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 7px;
        bottom: 7px;
        left: 0;
        width: 1px;
        background-color: $dark;
      }
    }

    .iwita-close {
      font-size: rem(14);
    }
  }
}
