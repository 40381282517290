.navbar-nav {
  font-size: rem(17);

  .nav-item {
    &.active, &:hover {
      .nav-link {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 6px;
          height: 2px;
          background-color: $red;
        }
      }
    }
  }
}

.navbar .navbar-nav {
  flex-direction: row;

  .nav-item:not(:last-child) .nav-link {
    margin-right: rem(15);
  }

  .nav-link {
    padding-left: 0;
    padding-right: 0;
    margin-left: rem(15);
  }

  @include media-breakpoint-up(xl) {
    .dropdown-menu {
      position: absolute;
    }
  }
}
