@use 'sass:math' as math;

$main-card-img-size: 124px;

.main-card {
  border-radius: $card-border-radius;
  background-color: $yellow;

  margin-top: math.div($main-card-img-size, 2);
  padding: calc(math.div($main-card-img-size, 2) + rem(21)) rem(21) rem(21) rem(21);
  position: relative;
  height: calc(100% - math.div($main-card-img-size, 2));

  .img-block {
    width: $main-card-img-size;
    height: $main-card-img-size;

    border-radius: 50%;
    border: 10px solid $gray-100;

    overflow: hidden;

    position: absolute;
    top: -(math.div($main-card-img-size, 2));
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }

  hr {
    border-color: $yellow-dark;
    margin-top: rem(32);
    margin-bottom: rem(32);
  }
}

.card-list {
  .card-header {
    background-color: $yellow;
    border: 0;
    border-top-left-radius: rem(15);
    border-top-right-radius: rem(15);
    padding: rem(25);
  }

  .card-body {
    background-color: #fff;
    border-left: 1px solid $gray-200;
    border-right: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;
    border-bottom-left-radius: rem(15);
    border-bottom-right-radius: rem(15);
  }
}

.card-rounded {
  border-radius: $card-border-radius !important;
}


.card {
  overflow: hidden;
  display: block;
  position: relative;
  transition: 0.3s ease;
  hyphens: auto;

  &:hover {
    transform: translateY(-10px);

    .card-body {
      background-color: $red;
      color: white;
    }
  }
}
