ul, ol {
  &:not(.breadcrumb):not(.pagination):not(.navbar-nav) {
    ul, ol {
      margin-top: .5em;
    }

    li + li {
      margin-top: .5em;
    }
  }
}

ul, ol {
  padding-left: 1.2rem;
}

ul {
  list-style-type: disc;
}

.list-check {
  padding-left: 2rem;
  list-style-type: none;

  li {
      position: relative;

      &:before {
          position: absolute;
          top: 2px;
          left: -2rem;
      }

      & + li {
          margin-top: rem(10);
      }
  }
}

.list-check {
  li {
      &:before {
          @include font-wita-icon($iwita-check);
          color: $red;
          font-size: 1rem;
      }
  }
}
