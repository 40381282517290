.dropdown {
  .dropdown-toggle {
    &::after {
      border: 0;
      @include font-wita-icon($iwita-angle-down, 6);
      vertical-align: middle;
      margin-left: 0.5rem;
    }

    &[aria-expanded="true"]::after {
      @include font-wita-icon($iwita-angle-up, 6);
    }
  }
}
