.modal.modal-search {
  padding-right: 0 !important;
  background-color: $gray-300;

  .modal-logo {
    position: absolute;
    left: 1.5rem;
    top: .5rem;
    z-index: 1;

    @include media-breakpoint-down(lg) {
      left: 50%;
      transform: translateX(calc(-50% + -7px));
    }
  }

  .close-icon {
    position: absolute;
    right: 2rem;
    top: calc(.5rem + 35px - 21px); // .5rem = start, 35px = half of mobile logo height, 21px = half of close button height
    z-index: 1;
  }

  .modal-dialog {
    max-width: none;
    margin: 0;
    display: flex;
    align-items: center;
    height: 100%;

    .modal-content {
      border-radius: 0;
      border: 0;
      background: none;

      .modal-body {
        padding: 3rem 1.5rem;
      }
    }
  }

  .input-group {
    max-width: 754px;
    width: 100%;

    input {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      background-color: transparent;
      border-radius: 0;
      color: $dark;
      padding-left: 0;

      &::placeholder {
        color: $dark;
      }
    }

    .btn {
      border-radius: 0;
      border-bottom: 1px solid $dark;
    }
  }

  @include media-breakpoint-up(xl) {
    .modal-logo {
      top: 1.5rem;
    }

    .close-icon {
      top: calc(1.5rem + 50px - 21px); // 1.5rem = start, 50px = half of logo height, 21px = half of close button height
    }
  }
}

.modal-sm {
  max-width: 360px;
}


