.info, .info-block, .law-block {
  position: relative;
  text-align: left !important;

  &:before {
      position: absolute;
      top: -3px;
      left: 0;
  }
}

.info, .info-block {
  padding-left: rem(32);

  &:before {
      @include font-wita-icon($iwita-info);
      color: $red;
  }
}

.info {
  &::before {
    font-size: rem(25);
  }
}

.info-block {
  padding: rem(20) rem(20) rem(20) rem(65);
  border-radius: $border-radius;
  background-color: $light;

  &:not(:first-child) {
    margin-top: 2rem;
  }
  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  &:before {
    left: rem(20);
    top: calc(rem(20) - 10px);
    font-size: 30px;
  }
}

.law-block {
  padding-left: 5rem;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;

  &:not(:first-child) {
    margin-top: 1.5rem;
  }
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  &:before {
      @include font-wita-icon($iwita-law);
      font-size: 2rem;
      color: $dark;
      background-color: $yellow;

      width: 4rem;
      height: 4rem;
      border-radius: 2rem;
      line-height: 4rem;
      text-align: center;

      top: 50%;
      transform: translateY(-50%);
  }
}